import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { HomeComponent } from './home/home.component';
import { AjustesComponent } from './ajustes/ajustes.component';

export const AppRoutes: Routes = [
  {
    path: '',
    component: FullComponent,
    children: [
      {
        path: '',
        redirectTo: '/authentication/login',
        pathMatch: 'full'
      },
      {
        path: 'home',
        redirectTo: '/dashboards/dashboard1',
        pathMatch: 'full'
      },
      {
        path: 'settings',
        component: AjustesComponent
      },
      {
        path: 'dashboards',
        loadChildren: './dashboards/dashboards.module#DashboardsModule'
      },
      {
        path: 'material',
        loadChildren:
          './material-component/material.module#MaterialComponentsModule'
      },
      {
        path: 'users',
        loadChildren:
          './users/users.module#UsersComponentsModule'
      },
      {
        path: 'faqs',
        loadChildren:
          './faqs/faqs.module#FaqsComponentsModule'
      },
      {
        path: 'news',
        loadChildren:
          './news/news.module#NewsComponentsModule'
      },
      {
        path: 'pages',
        loadChildren:
          './pages/pages.module#PagesComponentsModule'
      },
      {
        path: 'notifications',
        loadChildren:
          './notifications/notifications.module#NotificationsComponentsModule'
      }
    ]
  },
  {
    path: '',
    component: AppBlankComponent,
    children: [
      {
        path: 'authentication',
        loadChildren:
          './authentication/authentication.module#AuthenticationModule'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'authentication/404'
  }
];
