import { Component, AfterViewInit } from '@angular/core';
import * as Chartist from 'chartist';
import { ChartType, ChartEvent } from 'ng-chartist/dist/chartist.component';
declare var require: any;
import { Observable, Subscription } from 'rxjs/Rx';
import { GlobalVariable } from '../../global';
import { HttpClient } from '@angular/common/http';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar,MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from "@angular/router";

import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'app-ajustes',
  templateUrl: './ajustes.component.html',
  styleUrls: ['./ajustes.component.scss']
})
export class AjustesComponent{
  form = new FormGroup ({
    version: new FormControl()
  });


  form2 = new FormGroup ({
    meses: new FormControl(''),
    anos: new FormControl('')
  });
  anos:any;

  constructor(private httpClient: HttpClient,private dialog: MatDialog,private snackBar: MatSnackBar,private route: ActivatedRoute) {
    this.httpClient.get(GlobalVariable._API_URL_+"ajustes/get/version").subscribe((data:any) => {
      this.form.controls['version'].setValue(data);
    });

    this.httpClient.get(GlobalVariable._API_URL_+"ajustes/anos/get").subscribe((data:any) => {
        this.anos = data;
    });
  }
 

  abrir_pdf(valor){
    valor = "scripts/excel.php?token=kewriudjsfuy234j732jdsfy3423fesf&ano="+valor.anos+"&mes="+valor.meses;
    window.open(valor, "_blank");
  }
  
  onSubmit(valores){
      this.httpClient.post(GlobalVariable._API_URL_+"ajustes/update",{data:valores}).subscribe((data:any) => {
          if(data.result == 1){
            this.snackBar.open('Se ha modificado correctamente', '', {
              duration: 2000,
              panelClass:['snack-success']
            });    
          } else {
            this.snackBar.open('No se ha modificado', '', {
              duration: 2000,
              panelClass:['snack-error']
            });    
          }
      });
  }

}
