import { Component } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { ActivatedRoute,Router } from "@angular/router";
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {
  public config: PerfectScrollbarConfigInterface = {};
  // This is for Notifications

  constructor(private router: Router) {


  }


  salir(){
    localStorage.setItem('token','');
    localStorage.setItem('permisos','');
    this.router.navigate(['/authentication/login']);
  }
  
  
}

