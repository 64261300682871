import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

// content_copy

const MENUITEMS = [
  {
    state: 'users',
    name: 'Users',
    type: 'link',
    icon: 'perm_contact_calendar'
  },
  {
    state: 'news',
    name: 'News',
    type: 'link',
    icon: 'web'
  },
  {
    state: 'pages',
    name: 'Pages',
    type: 'link',
    icon: 'content_copy'
  },
  {
    state: 'notifications',
    name: 'Notifications',
    type: 'link',
    icon: 'star'
  },
  {
    state: 'faqs',
    name: 'Faqs',
    type: 'link',
    icon: 'report_problem'
  },
  {
    state: 'settings',
    name: 'Settings',
    type: 'link',
    icon: 'build'
  }
];


@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
